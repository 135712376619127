import bubble from "../../images/bubble.gif";
import seal from "../../images/seal.gif";

import zotpals1 from "../../images/zotpals1.png";
import zotpals2 from "../../images/zotpals2.png";
import zotpals3 from "../../images/zotpals3.png";
import zotpals4 from "../../images/zotpals4.png";
import zotpals5 from "../../images/zotpals5.png";
import github from "../../images/github.svg";
import school from "../../images/school.png";

import havefun1 from "../../images/havefun1.png";
import havefun2 from "../../images/havefun2.png";
import havefun4 from "../../images/havefun4.png";
import havefun5 from "../../images/havefun5.png";

import zelp1 from "../../images/zelp1.png";
import zelp2 from "../../images/zelp2.png";

import ttt_logo from "../../images/ttt_logo.png";
import ttt1 from "../../images/ttt1.png";
import ttt2 from "../../images/ttt2.png";
import ttt3 from "../../images/ttt3.png";
import ttt4 from "../../images/ttt4.png";

import comp from "../../images/comp.svg";
import devpost_logo from "../../images/devpost_logo.png";

import ctc from "../../images/ctc.webp";
import antalert from "../../images/antalert.png";
import morep from "../../images/morep.png";
import moreproj from "../../images/moreproj.gif";

import zotpals_text from "../../images/zotpals_text.png";
import zotpals_logo from "../../images/zotpals_logo.png";
import havefun_text from "../../images/havefun_text.png";
import havefun_logo from "../../images/havefun_logo.png";
import zelp_text from "../../images/zelp_text.png";
import zelp_logo from "../../images/zelp_logo.png";
import tttt_text from "../../images/tttt_text.png";

import zotnfound_1 from "../../images/zotnfound_1.png";
import zotnfound_3 from "../../images/zotnfound_3.png";
import zotnfound_4 from "../../images/zotnfound_4.png";
import zotnfound_5 from "../../images/zotnfound_5.png";

import zotnfound from "../../images/zotnfound.png";
import zotnfound_logo from "../../images/zotnfound_logo.png";
import mp from "../../images/mp.png";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from "react95";

import "./ProjectPage.css";

const schema = z.object({
  name: z.string().min(1,"Name is required!"),
  email: z.string().min(1,"Email is required!").email(),
  socialMedia: z.string().optional(),
  message: z.string().optional(),
});

export default function ProjectPage({setIsProjectOpen,setSuccessMessage}) {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema)
  });

  const onSubmit = (data) => {
    console.log(data);
    setIsProjectOpen(false);
    setSuccessMessage(true);
   
  };

  return (
    <>
      <div className="message-form">
        <h3 style={{ fontWeight: "bold" }}>Please enter your details to reach us.</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-field">
            <label className="input-label">Name<span className="red-text">*</span>:</label>
            <input
              type="text"
              {...register("name")} 
              placeholder="Your Name"
            />
            {errors.name && <span className="error-text red-text">{errors.name.message}</span>}
          </div>
          <div className="input-field">
            <label className="input-label">Email<span className="red-text">*</span>:</label>
            <input
              type="text"
              {...register("email")} 
              placeholder="Your Email"
            />
            {errors.email && <span className="error-text red-text">{errors.email.message}</span>} 
          </div>
          <div className="input-field">
            <label className="input-label">Social Media:</label>
            <input
              type="text"
              {...register("socialMedia")} 
              placeholder="Social Media URL"
            />
          </div>
          <div className="input-field">
            <label className="input-label">Message:</label>
            <textarea
              {...register("message")} 
              placeholder="Your Message"
            />
          </div>
          <div className="submit-btn">
            <Button
              style={{ fontWeight: "bold", width: '100%' }}
              type="submit"
            >
              Send
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
