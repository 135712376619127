import React, { useState } from "react";
import games from "../../images/games.png";
import pc from "../../images/pc.png";
import drug from "../../images/drug.png";
import hood from "../../images/hoodb.png";
import docent from "../../images/docent.png";
import alive from "../../images/alive.png";
import www from "../../images/www.png";
import camera from "../../images/camera.png";
import "./DesktopApp.css";
import "98.css";
import DraggableIcon from "./DraggableIcon";

const PcIcons = (props) => {
  const [items] = useState([
    {
      id: "pc",
      src: pc,
      text: "The Lonely Stoner Show",
      position: { x: 0, y: 0 },
    },
    {
      id: "drug",
      src: drug,
      text: "Losing the War on Drugs",
      position: { x: 0, y: 0 },
    },
    {
      id: "docent",
      src: docent,
      text: "The MicoDosing Museum Docent",
      position: { x: 0, y: 0 },
    },
    {
      id: "alive",
      src: alive,
      text: "How I Stayed Alive When…",
      position: { x: 0, y: 0 },
    },
    { id: "games", src: games, text: "Games", position: { x: 0, y: 0 } },
    { id: "hood", src: hood, text: "Shop", position: { x: 0, y: 0 } },
    {
      id: "www",
      src: www,
      text: "Connect to the Internet",
      position: { x: 0, y: 0 },
    },
    { id: "photos", src: camera, text: "My Photos", position: { x: 0, y: 0 } },
  ]);

  return (
    <div className="desktop-app">
      {items.map(({ id, src, text }, index) => (
        <DraggableIcon
          id={id}
          src={src}
          text={text}
          key={index}
          setIsMarketOpen={props.setIsMarketOpen}
        />
      ))}
    </div>
  );
};

export default PcIcons;
