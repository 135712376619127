import React, { useEffect, useState } from "react";
import { Button, TextInput, Toolbar, Window, WindowContent, WindowHeader } from "react95";
import "../MyWindow/MyWindow.css";
import "./MarketWindow.css";
import minimize from "../../images/minimize.svg";
import maximize from "../../images/maximize.svg";
import close from "../../images/close.svg";
import back from "../../images/back.png";

import Draggable from "react-draggable";
import MarketPage from "../MarketPage/MarketPage";
export default function MarketWindow({ setIsMarketOpen }) {
  const [isMax, setIsMax] = useState(false);
  const [desktopOpen, setDesktopOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [folderPath, setFolderPath] = useState('');
  const [loading, setLoading] = useState(false);

  const getAddressPath = () => {
    let result = '';
    let loading = false;
    if (!mobileOpen && !desktopOpen) {
     loading  = true;
      result = 'D:'
    }
    else if (mobileOpen) {
      loading  = true;
      result = 'D:\\Mobile'
    }
    else if (desktopOpen) {
      loading  = true;
      result = 'D:\\Desktop'
    }
    if (loading) {
      setLoading(true);
    }
    return result;
  }

  useEffect(() => {
    const result = getAddressPath();
    if (result) {
      setLoading(false);
      setFolderPath(result);
    }

  }, [mobileOpen, desktopOpen]);

  console.log(folderPath, 'folderpath')


  return (
    <div
      className={
        !isMax ? "market-window-div window-div" : "window-div max-window-div"
      }
    >
      <Draggable handle="strong" cancel=".btn">
        <Window resizable className={!isMax ? "window" : "window max-window"}>
          <strong className="cursor">
            <WindowHeader className="title-bar">
              <div class="title-bar-text">Games</div>
              <div class="title-bar-controls">
                <Button
                  onClick={() => {
                    setIsMarketOpen(false);
                  }}
                  className="btn"
                >
                  <img className="window-btn" src={minimize} alt="" />
                </Button>
                <Button
                  onClick={() => {
                    setIsMax(!isMax);
                  }}
                  className="btn"
                >
                  <img className="window-btn" src={maximize} alt="" />
                </Button>
                <Button
                  onClick={() => {
                    setIsMarketOpen(false);
                  }}
                  className="btn"
                >
                  <img className="window-btn" src={close} alt="" />
                </Button>
              </div>
            </WindowHeader>
          </strong>
          {/* <Toolbar>
            <Button variant="menu" size="sm">
              File
            </Button>
            <Button variant="menu" size="sm">
              Edit
            </Button>
            <Button variant="menu" size="sm" disabled>
              Save
            </Button>
          </Toolbar> */}
          {!loading &&
            <Toolbar>
              <div className="back-btn">
                <Button
                  onClick={() => {
                    setDesktopOpen(false)
                    setMobileOpen(false)
                  }
                  }>
                  <img className="bacl-btn" src={back} alt="back" />
                </Button>
              </div>
              <TextInput
                defaultValue={folderPath}
                fullWidth
              />
            </Toolbar>
          }
          <WindowContent>
            <div
              className={
                !isMax
                  ? "market-content my-content folder-contnt"
                  : "market-content my-content max-my-content"
              }
            >
              <MarketPage
                desktopOpen={desktopOpen}
                mobileOpen={mobileOpen}
                setDesktopOpen={setDesktopOpen}
                setMobileOpen={setMobileOpen}
              />
            </div>
          </WindowContent>
        </Window>
      </Draggable>
    </div>
  );
}
