import React, { useState, useRef } from "react";
import Draggable from "react-draggable";
import "./DesktopApp.css";
import "98.css";
import PhotosWindow from "../Photos/Photos";

const DraggableIcon = ({ id, src, text, setIsMarketOpen,className, position }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const iconRef = useRef(null);
  const [isPhotos, setIsPhotos] = useState(false);

  const onMouseDown = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const onTouchStart = (e) => {
    const touch = e.touches[0];
    setMousePosition({ x: touch.clientX, y: touch.clientY });
  };

  const onMouseUp = (e) => {
    const x = e.clientX;
    const y = e.clientY;
    const { x: stateX, y: stateY } = mousePosition;
    if (x === stateX && y === stateY) {
      if (id === "games") {
        setIsMarketOpen(true);
      } else if (id === 'photos') {
        setIsPhotos(true);
      } else {
        window.open("https://youtube.com");
      }
    }
  };

  const onTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const x = touch.clientX;
    const y = touch.clientY;
    const { x: stateX, y: stateY } = mousePosition;
    if (x === stateX && y === stateY) {
      if (id === "games") {
        setIsMarketOpen(true);
      } else if (id === 'photos') {
        setIsPhotos(true);
      } else {
        window.open("https://youtube.com");
      }
    }
  };

  return (
    <>
      <div
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        ref={iconRef}
        id={`icon-${id}`}
        className={className}
      >
        <Draggable key={id} position={position}>
          <div className="logo-box">
            {id === "games" ? (
              <div>
                <img className="desktop-logo" draggable="false" src={src} alt="" />
                <p>{text}</p>
              </div>
            ) : (
              <span>
                <img className="desktop-logo" draggable="false" src={src} alt="" />
                <p>{text}</p>
              </span>
            )}
          </div>
        </Draggable>
      </div>
      {isPhotos && <PhotosWindow setIsPhotos={setIsPhotos} />}     
    </>
  );
};

export default DraggableIcon;
