import React, { useEffect, useState } from "react";
import "./DesktopApp.css";
import "98.css";
import PcIcons from "./PcIcons";
import MobileIcons from "./MobileIcons";

const DesktopApp = () => {
  const [isPc, setIsPc] = useState(false) 
  const width = window.innerWidth;
  useEffect(() => {
    if (width < 600) {
      setIsPc(false);
    } else {
      setIsPc(true);
    }
  }, []);
  return <>{isPc ? <PcIcons /> : <MobileIcons/>}</>;
};

export default DesktopApp;