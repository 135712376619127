import React, { useRef, useState, useEffect } from "react";
import {
  AppBar,
  Button,
  MenuList,
  MenuListItem,
  Separator,
  TextInput,
  Toolbar,
} from "react95";
import windows from "../images/windows.png";
import insta from "../images/insta.png";
import tiktok from "../images/tiktok.png";
import message from "../images/messages.png";
import utube from "../images/you-tube.png";
// import email from "../images/email.svg";
// import camera from "../images/camera.png";
import ProjectWindow from "./ProjectWindow/ProjectWindow";
// import PhotosWindow from "./Photos/Photos";

export default function BottomBar({ ContactOpen, setContactOpen,setSuccessMessage }) {
  const menuRef = useRef(null);
  const [isProjectOpen, setIsProjectOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  // const [isPhotos, setIsPhotos] = useState(false);
  const handleInstagramClick = (event) => {
    event.stopPropagation();
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const handleCloseMenu = () => {
    setContactOpen(!ContactOpen); 
    setIsSubMenuOpen(false); 
  };

  useEffect(() => {
    // Function to close the menu when clicked outside
    const handleClickOutside = (event) => {
      if (ContactOpen && menuRef.current && !menuRef.current.contains(event.target)) {
        setContactOpen(false);
        setIsSubMenuOpen(false);
      }
    };

    if (ContactOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ContactOpen]);



  return (
    <>
      {isProjectOpen && <ProjectWindow setIsProjectOpen={setIsProjectOpen} setSuccessMessage={setSuccessMessage}/>}

      <AppBar className="myAppbar">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div className="menubar"  ref={menuRef} style={{ position: "relative", display: "inline-block" }}>
            <Button
              onClick={handleCloseMenu}
              active={ContactOpen}
              style={{ fontWeight: "bold" }}
            >
              <img className="windows-logo" src={windows} alt="" />
              CONTACT
            </Button>
            {ContactOpen && (
              <MenuList
                style={{
                  position: "absolute",
                  left: "0",
                  bottom: "100%",
                  zIndex: "9",
                }}
                onClick={() => setContactOpen(false)}
              >

                {/* TikTok menu item */}
                <MenuListItem onClick={handleInstagramClick}>
                  <img style={{ width: "25px" }} src={tiktok} alt="hi" />
                  <p> &nbsp; TikTok</p>
                </MenuListItem>
                {/* Tiktok Submenu */}
                {isSubMenuOpen && (
                  <MenuList
                    style={{
                      position: "absolute",
                      left: "100%",
                      top: "0",
                      zIndex: "10",
                    }}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <MenuListItem>
                      <a
                        href="https://www.youtubr.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Sub Menu
                      </a>
                    </MenuListItem>
                    <MenuListItem>
                      <a
                        href="https://www.youtube.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Sub Menu
                      </a>
                    </MenuListItem>
                  </MenuList>
                )}
                {/* End of Submenu */}

                {/* End of tiktok submenu */}

                {/* YoutTube menu item */}
                <MenuListItem onClick={handleInstagramClick}>
                  <img style={{ width: "25px" }} src={utube} alt="hi" />
                  <p> &nbsp; Youtube</p>
                </MenuListItem>
                {/* Submenu for Youtube */}
                {isSubMenuOpen && (
                  <MenuList
                    style={{
                      position: "absolute",
                      left: "100%",
                      top: "0",
                      zIndex: "10",
                    }}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <MenuListItem>
                      <a
                        href="https://www.youtubr.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Sub Menu
                      </a>
                    </MenuListItem>
                    <MenuListItem>
                      <a
                        href="https://www.youtube.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Sub Menu
                      </a>
                    </MenuListItem>
                  </MenuList>
                )}
                {/* End of Submenu */}

                {/* End of Youtube submenu */}

                {/* <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
                <MenuListItem>
                  <img style={{ width: "25px" }} src={insta} alt="hi" />
                  <p> &nbsp; Instagram</p>
                </MenuListItem>
              </a> */}

                {/* Instagram menu item */}
                <MenuListItem onClick={handleInstagramClick}>
                  <img style={{ width: "25px" }} src={insta} alt="hi" />
                  <p> &nbsp; Instagram</p>
                </MenuListItem>
                {/* Submenu for Instagram */}
                {isSubMenuOpen && (
                  <MenuList
                    style={{
                      position: "absolute",
                      left: "100%",
                      top: "0",
                      zIndex: "10",

                    }}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <MenuListItem>
                      <a
                        href="https://www.instagram.com"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          setIsSubMenuOpen(false)
                          setContactOpen(false)
                        }}
                      >
                        Sub Menu
                      </a>
                    </MenuListItem>
                    <MenuListItem>
                      <a
                        href="https://www.instagram.com"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          setIsSubMenuOpen(false)
                          setContactOpen(false)
                        }}                      >
                        Sub Menu
                      </a>
                    </MenuListItem>
                  </MenuList>
                )}
                {/* End of Submenu */}

                {/* End of Instagram submenu */}

                <a onClick={() => setIsProjectOpen(true)} rel="noreferrer">
                  <MenuListItem>
                    <img style={{ width: "25px" }} src={message} alt="hi" />
                    <p> &nbsp; Message</p>
                  </MenuListItem>
                </a>
                <Separator />
                <MenuListItem disabled>
                  <span role="img" aria-label="🔙">
                    🔙
                  </span>
                  Logout
                </MenuListItem>
              </MenuList>
            )}
          </div>
          {/* <TextInput disabled={false} placeholder="Search..." width={150} /> */}
          {/* <div className="my-photos">
            {isPhotos && <PhotosWindow setIsPhotos={setIsPhotos} />}

            <a onClick={() => setIsPhotos(true)} rel="noreferrer" style={{ cursor: "pointer" }}>
              <img style={{ width: "25px" }} src={camera} alt="camera" />
            </a>
          </div> */}
        </Toolbar>
      </AppBar>
    </>
  );
}
