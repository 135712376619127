import React, { useEffect, useState } from "react";
import { Button, TextInput, Toolbar, Window, WindowContent, WindowHeader } from "react95";
import "./photos.css";
import minimize from "../../images/minimize.svg";
import maximize from "../../images/maximize.svg";
import close from "../../images/close.svg";
import pic from "../../images/pic.JPG";
import pic1 from "../../images/pic1.JPG";
import pic3 from "../../images/pic3.JPG";
import pic4 from "../../images/pic4.jpg";
import pic5 from "../../images/pic5.jpg";
import pic7 from "../../images/pic7.jpg";
import pic6 from "../../images/pic6.JPG";
import pic8 from "../../images/pic8.JPG";
import gif from "../../images/gif.gif";
import Draggable from "react-draggable";
export default function PhotosWindow({ setIsPhotos }) {
  const [showPhotos, setShowPhotos] = useState(false);


  return (
    <div
      className={
        !showPhotos ? "market-window-div window-div photos" : "window-div max-window-div"
      }
    >
      <Draggable handle="strong" cancel=".btn">
        <Window resizable className={!showPhotos ? "window" : "window max-window"}>
          <strong className="cursor">
            <WindowHeader className="title-bar">
              <div class="title-bar-text">Photos</div>
              <div class="title-bar-controls">
                <Button
                  onClick={() => {
                    setIsPhotos(false);
                  }}
                  className="btn"
                >
                  <img className="window-btn" src={minimize} alt="" />
                </Button>
                <Button
                  onClick={() => {
                    setShowPhotos(!showPhotos);
                  }}
                  className="btn"
                >
                  <img className="window-btn" src={maximize} alt="" />
                </Button>
                <Button
                  onClick={() => {
                    setIsPhotos(false);
                  }}
                  className="btn"
                >
                  <img className="window-btn" src={close} alt="" />
                </Button>
              </div>
            </WindowHeader>
          </strong>
          {/* <Toolbar>
            <Button variant="menu" size="sm">
              File
            </Button>
            <Button variant="menu" size="sm">
              Edit
            </Button>
            <Button variant="menu" size="sm" disabled>
              Save
            </Button>
          </Toolbar> */}
          
          <WindowContent>
            <div
              className={
                !showPhotos
                  ? "photos-content my-content"
                  : "photos-content my-content"
              }
            >
             <div className="photos-content">
                <div className="photos">
                    <div className="top-gif">
                       <img src={gif} alt="gif" />
                    </div>
                    <div className="phto-row">
                            <div className="photo-view">
                                <img src={pic} alt="photo"/>
                            </div>
                            <div className="photo-view">
                                <img src={pic1} alt="photo"/>
                            </div>
                            <div className="photo-view">
                                <img src={pic3} alt="photo"/>
                            </div>
                            <div className="photo-view">
                                <img src={pic4} alt="photo"/>
                            </div>
                            <div className="photo-view">
                                <img src={pic5} alt="photo"/>
                            </div>
                            <div className="photo-view">
                                <img src={pic7} alt="photo"/>
                            </div>
                            <div className="photo-view">
                                <img src={pic6} alt="photo"/>
                            </div>
                            <div className="photo-view">
                                <img src={pic8} alt="photo"/>
                            </div>
                            <div className="photo-view">
                                <img src={pic} alt="photo"/>
                            </div>
                            <div className="photo-view">
                                <img src={pic} alt="photo"/>
                            </div>
                    </div>
                </div>
             </div>
            </div>
          </WindowContent>
        </Window>
      </Draggable>
    </div>
  );
}
