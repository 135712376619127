import React, { useState } from "react";
import "./MarketPage.css";

import MarketItems from "./MarketItems";
import prince from "../../images/prince.png";
import wolf from "../../images/wolf.png";
import earthworm from "../../images/earthworm.png";
import oregon from "../../images/oregon.png";
import chex from "../../images/chex.png";
import folder from "../../images/folder.png";
import star from "../../images/starwars.png";
import lexa from "../../images/lexo.png";
import pvz from "../../images/pvz.png";
import snake from "../../images/snake.png";

export default function MarketPage({ desktopOpen, mobileOpen, setDesktopOpen, setMobileOpen }) {
  const [subiconsVisible, setSubiconsVisible] = useState(false);


  const toggleSubicons = () => {
    setSubiconsVisible(!subiconsVisible);
    setDesktopOpen(true);
  };

  const handleMobileClick = () => {
    setMobileOpen(true);
  };

  return (
    <div className="market-content games-folder">
      {!desktopOpen && !mobileOpen &&
        <div className="folder-icons main-folder">
          <div style={{ cursor: "pointer" }} className="folder-box">
            <div
              onClick={toggleSubicons}
              target="_blank"
              rel="noreferrer"
            >
              <img className="folder-logo" src={folder} alt="" />
              <p>Desktop</p>
            </div>
          </div>
          <div style={{ cursor: "pointer" }} className="folder-box">
            <div
              onClick={handleMobileClick}
              target="_blank"
              rel="noreferrer"
            >
              <img className="folder-logo" src={folder} alt="" />
              <p>Mobile</p>
            </div>
          </div>
        </div>
      }
      <div className={subiconsVisible ? "folder-icons subicons" : "folder-icons subicons hide"}>
        {desktopOpen &&
          <>
            <div style={{ cursor: "pointer" }} className="folder-box">
              <a
                href="https://dos.zone/prince-of-persia-1990/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="desktop-logo" src={prince} alt="" />
                <p>Price Of Persia</p>
              </a>
            </div>
            <div style={{ cursor: "pointer" }} className="folder-box">
              <a
                href="https://dos.zone/wolfenstein-3d-may-05-1992/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="desktop-logo" src={wolf} alt="" />
                <p>Wolfenstein 3D</p>
              </a>
            </div>
            <div style={{ cursor: "pointer" }} className="folder-box">
              <a
                href="https://dos.zone/earthworm-jim-nov-30-1995"
                target="_blank"
                rel="noreferrer"
              >
                <img className="desktop-logo" src={earthworm} alt="" />
                <p>Earthworm Jim</p>
              </a>
            </div>
            <div style={{ cursor: "pointer" }} className="folder-box">
              <a
                href="https://dos.zone/oregon-trail-deluxe-1992/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="desktop-logo" src={oregon} alt="" />
                <p>Oregon Trail</p>
              </a>
            </div>
            <div style={{ cursor: "pointer" }} className="folder-box">
              <a
                href="https://dos.zone/chex-quest-1996/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="desktop-logo" src={chex} alt="" />
                <p>Chex Quest</p>
              </a>
            </div>
          </>
        }
        {mobileOpen &&
          <>

            <div style={{ cursor: "pointer" }} className="folder-box mobile">
              <a
                href="https://www.lexaloffle.com/bbs/?tid=29272"
                target="_blank"
                rel="noreferrer"
              >
                <img className="desktop-logo" src={star} alt="" />
                <p>Star Wars</p>
              </a>
            </div>
            <div style={{ cursor: "pointer" }} className="folder-box">
              <a
                href="https://www.lexaloffle.com/bbs/?pid=136646#p"
                target="_blank"
                rel="noreferrer"
              >
                <img className="desktop-logo" src={lexa} alt="" />
                <p>Lexaloffle</p>
              </a>
            </div>
            <div style={{ cursor: "pointer" }} className="folder-box">
              <a
                href="https://pvz.ee/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="desktop-logo" src={pvz} alt="" />
                <p>PVZ</p>
              </a>
            </div>
            <div style={{ cursor: "pointer" }} className="folder-box">
              <a
                href="https://playsnake.org/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="desktop-logo" src={snake} alt="" />
                <p>Playsnake</p>
              </a>
            </div>
          </>
        }
      </div>
    </div>
  );
}
